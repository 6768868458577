.body {
    width: 65vw;
    margin: 0 auto;
}

.container {
    min-height: 80vh;
    display: grid;
    grid-template-columns: 30% 70%;
    margin-bottom: 100px;
}

.sidebar {
    background-color: var(--auto_container_color_2);
    border-radius: 20px 0 0 20px;
    opacity: .8;
}

#first_link {
    border-radius: 16px 6px 6px 6px;
}

.selected {
    font-weight: 700;
    background-color: var(--auto_accent_color_2);
}

.link {
    text-decoration: none;
    color: var(--auto_text_color);
}

.settings_link {
    flex-grow: 1;
    font-size: 1.2rem;
    margin: 4px;
    padding: 10px 20px;
    border-radius: 6px;
    transition: 0.2s;
}

.settings_link:hover {
    background-color: var(--auto_accent_color_1);
    color: var(--auto_selected_button_text_color);
    transition: 0.2s;
}

.setting_icon {
    margin-right: 8px;
    margin-bottom: -2px;
}

@media only screen and (max-width: 850px) {
    .body {
        width: 95vw;
        margin: 0 auto;
    }
    
    .sidebar {
        width: 64px;
        text-align: center;
    }

    .container {
        grid-template-columns: 64px auto;
    }
    

    .settings_link {
        font-size: 1rem;
        margin: 0 auto;
        padding: 0px 0px;
    }

    .setting_icon {
        margin: 0;
        margin: 0 auto;
        font-size: 1.7rem;
        height: 64px;
    }

    .setting_link_title {
        display: none;
    }
}
