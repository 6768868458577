:root {
    /* Couleur a l'origine du theme (Hue et Saturation) */
    /* --base_h: 270;*/
    --base_h: 270;
    --base_s: 30%;


    /* ===================================== */
    /*               Couleurs                */
    /* ===================================== */

    /* Noir */
    --dark_0: hsl(var(--base_h), var(--base_s), 0%);
    /* Blanc */
    --light_0: hsl(var(--base_h), var(--base_s), 100%);

    --dark_2: hsl(var(--base_h), var(--base_s), 2%);
    --light_2: hsl(var(--base_h), var(--base_s), 98%);

    --dark_4: hsl(var(--base_h), var(--base_s), 4%);
    --light_4: hsl(var(--base_h), var(--base_s), 96%);

    --dark_5: hsl(var(--base_h), var(--base_s), 5%);
    --light_5: hsl(var(--base_h), var(--base_s), 95%);

    --dark_6: hsl(var(--base_h), var(--base_s), 6%);
    --light_6: hsl(var(--base_h), var(--base_s), 94%);

    --dark_7: hsl(var(--base_h), var(--base_s), 7%);
    --light_7: hsl(var(--base_h), var(--base_s), 93%);

    --dark_8: hsl(var(--base_h), var(--base_s), 8%);
    --light_8: hsl(var(--base_h), var(--base_s), 92%);

    --dark_9: hsl(var(--base_h), var(--base_s), 9%);
    --light_9: hsl(var(--base_h), var(--base_s), 91%);

    --dark_10: hsl(var(--base_h), var(--base_s), 10%);
    --light_10: hsl(var(--base_h), var(--base_s), 90%);

    --dark_13: hsl(var(--base_h), var(--base_s), 13%);
    --light_13: hsl(var(--base_h), var(--base_s), 87%);

    --dark_15: hsl(var(--base_h), var(--base_s), 15%);
    --light_15: hsl(var(--base_h), var(--base_s), 85%);

    --dark_20: hsl(var(--base_h), var(--base_s), 20%);
    --light_20: hsl(var(--base_h), var(--base_s), 80%);

    --dark_25: hsl(var(--base_h), var(--base_s), 25%);
    --light_25: hsl(var(--base_h), var(--base_s), 75%);

    --dark_30: hsl(var(--base_h), var(--base_s), 30%);
    --light_30: hsl(var(--base_h), var(--base_s), 70%);

    --dark_35: hsl(var(--base_h), var(--base_s), 35%);
    --light_35: hsl(var(--base_h), var(--base_s), 65%);

    --dark_40: hsl(var(--base_h), var(--base_s), 40%);
    --light_40: hsl(var(--base_h), var(--base_s), 60%);

    --dark_45: hsl(var(--base_h), var(--base_s), 45%);
    --light_45: hsl(var(--base_h), var(--base_s), 55%);

    --dark_50: hsl(var(--base_h), var(--base_s), 50%);
    --light_50: hsl(var(--base_h), var(--base_s), 50%);

    --dark_55: hsl(var(--base_h), var(--base_s), 45%);
    --light_55: hsl(var(--base_h), var(--base_s), 55%);

    --dark_60: hsl(var(--base_h), var(--base_s), 60%);
    --light_60: hsl(var(--base_h), var(--base_s), 40%);

    --dark_80: hsl(var(--base_h), var(--base_s), 80%);
    --light_80: hsl(var(--base_h), var(--base_s), 20%);

    --dark_90: hsl(var(--base_h), var(--base_s), 90%);
    --light_90: hsl(var(--base_h), var(--base_s), 10%);

    --dark_100: hsl(var(--base_h), var(--base_s), 100%);
    --light_100: hsl(var(--base_h), var(--base_s), 0%);

    /* ====================================== */
    /*              Éléments CSS              */
    /* ====================================== */


    /*Couleur de fond des pages*/
    --background_dark: var(--dark_10);
    --background_light: var(--light_10);

    /*Couleur d'accent 1*/
    --on_background_dark_accent_color_1: var(--dark_60);
    --on_background_light_accent_color_1: var(--light_60);

    /*Couleur d'accent 2*/
    --on_background_dark_accent_color_2: var(--dark_40);
    --on_background_light_accent_color_2: var(--light_40);

    /*Couleur header*/
    --on_background_dark_header: hsla(var(--base_h), var(--base_s), 13%, 75%);
    --on_background_light_header: hsla(var(--base_h), var(--base_s), 87%, 75%);

    /*Couleur footer*/
    --on_background_dark_footer: var(--dark_40);
    --on_background_light_footer: var(--dark_40);

    /*Couleur des titres*/
    --on_background_dark_title: var(--dark_90);
    --on_background_light_title: var(--light_90);

    /*Couleur des sous-titres*/
    --on_background_dark_subtitles: var(--dark_90);
    --on_background_light_subtitles: var(--light_90);

    /*Couleur du texte*/
    --on_background_dark_text: var(--dark_90);
    --on_background_light_text: var(--light_90);

    /* Couleur des îcones */
    --on_background_dark_icon: var(--dark_0);
    --on_background_light_icon: var(--light_0);

    /* Couleur des conteneurs dégradées */
    --on_background_dark_container_gradient: linear-gradient(rgb(245, 185, 22), rgb(240, 117, 22), rgb(231, 39, 93));
    --on_background_light_container_gradient: linear-gradient(rgb(246, 92, 184), rgb(252, 163, 85), rgb(253, 221, 106));

    /*Couleur des conteneurs 1*/
    --on_background_dark_container_1: var(--dark_13);
    --on_background_light_container_1: var(--light_13);

    /*Couleur des conteneurs 2*/
    --on_background_dark_container_2: var(--dark_15);
    --on_background_light_container_2: var(--light_15);

    /*Couleur des conteneurs 3*/
    --on_background_dark_container_3: var(--dark_20);
    --on_background_light_container_3: var(--light_20);

    /*Couleur des conteneurs 4*/
    --on_background_dark_container_4: var(--dark_25);
    --on_background_light_container_4: var(--light_25);

    /*Couleur des conteneurs 5*/
    --on_background_dark_container_4: var(--dark_30);
    --on_background_light_container_4: var(--light_30);

    /*Couleur des étiquettes des champs texte*/
    --on_background_dark_label: var(--dark_80);
    --on_background_light_label: var(--light_80);

    /*Couleur des champs texte*/
    --on_background_dark_input_field: var(--dark_20);
    --on_background_light_input_field: var(--light_20);

    /*Couleur des du text placeholder dans les champs texte*/
    --on_background_dark_placeholder_text: var(--dark_70);
    --on_background_light_placeholder_text: var(--light_70);

    /* Couleur des zones texte */
    --on_background_dark_textarea: var(--on_background_dark_input_field);
    --on_background_light_textarea: var(--on_background_light_input_field);

    /* Couleur des boutons selectionnés */
    --on_background_dark_selected_button: var(--on_background_dark_accent_color_1);
    --on_background_light_selected_button: var(--on_background_light_accent_color_1);

    /* Couleur des boutons non selectionnés */
    --on_background_dark_unselected_button: var(--dark_15);
    --on_background_light_unselected_button: var(--light_15);

    /* Couleur du text des boutons selectionnés */
    --on_background_dark_selected_button_text: var(--dark_10);
    --on_background_light_selected_button_text: var(--light_10);

    /* Couleur du text des boutons non selectionnés */
    --on_background_dark_unselected_button_text: var(--dark_80);
    --on_background_light_unselected_button_text: var(--light_80);

    /* Couleur des boutons on hover */
    --on_background_dark_button_hover: var(--dark_10);
    --on_background_light_button_hover: var(--light_10);

    /* Couleur des bordures */
    --border_color_dark: var(--dark_40);
    --border_color_light: var(--light_40);


    /* ===================================== */
    /* VARIABLES QUI CHANGENT SELON LE THEME */
    /* ça utilise quand meme celles définies */
    /* en haut, mais elles changent selon le */
    /*         theme automatiquement         */
    /* ===================================== */
    /*                  | |                  */
    /*                  v v                  */

    /*Thème sombre*/

    --auto_background_color: var(--background_dark);
    --auto_accent_color_1: var(--on_background_dark_accent_color_1);
    --auto_accent_color_2: var(--on_background_dark_accent_colorborder_2);
    --auto_header_color: var(--on_background_dark_header);
    --auto_footer_color: var(--on_background_dark_footer);


    --auto_title_color: var(--on_background_dark_title);
    --auto_subtitle_color: var(--on_background_dark_subtitles);
    --auto_text_color: var(--on_background_dark_text);

    --auto_icon_color: var(--on_background_dark_icon);

    --auto_container_gradient_color: var(--on_background_dark_container_gradient);
    --auto_container_color_1: var(--on_background_dark_container_1);
    --auto_container_color_2: var(--on_background_dark_container_2);
    --auto_container_color_3: var(--on_background_dark_container_3);
    --auto_container_color_4: var(--on_background_dark_container_4);
    --auto_container_color_5: var(--on_background_dark_container_5);

    --auto_container_project_form_color: var(--on_background_dark_container_project_form);
    --auto_container_project_management_color: var(--on_background_dark_container_project_management);
    --auto_container_collaboration_management_color: var(--on_background_dark_container_collaboration_management);

    --auto_label_color: var(--on_background_dark_label);
    --auto_input_field_color: var(--on_background_dark_input_field);
    --auto_placeholder_text_color: var(--on_background_dark_placeholder_text);
    --auto_textarea_color: var(--on_background_dark_textarea);
    --auto_selected_button_color: var(--on_background_dark_selected_button);
    --auto_unselected_button_color: var(--on_background_dark_unselected_button);
    --auto_selected_button_text_color: var(--on_background_dark_selected_button_text);
    --auto_unselected_button_text_color: var(--on_background_dark_unselected_button_text);
    --auto_button_hover_color: var(--on_background_dark_button_hover);

    --auto_border_color: var(--border_color_dark);

    /*Thème clair*/
    @media (prefers-color-scheme: light) {

        --auto_background_color: var(--background_light);
        --auto_accent_color_1: var(--on_background_light_accent_color_1);
        --auto_accent_color_2: var(--on_background_light_accent_color_2);
        --auto_header_color: var(--on_background_light_header);
        --auto_footer_color: var(--on_background_light_footer);


        --auto_title_color: var(--on_background_light_title);
        --auto_subtitle_color: var(--on_background_light_subtitles);
        --auto_text_color: var(--on_background_light_text);

        --auto_icon_color: var(--on_background_light_icon);


        --auto_container_gradient_color: var(--on_background_light_container_gradient);
        --auto_container_color_1: var(--on_background_light_container_1);
        --auto_container_color_2: var(--on_background_light_container_2);
        --auto_container_color_3: var(--on_background_light_container_3);
        --auto_container_color_4: var(--on_background_light_container_4);
        --auto_container_color_5: var(--on_background_light_container_5);

        --auto_container_project_form_color: var(--on_background_light_container_project_form);
        --auto_container_project_management_color: var(--on_background_light_container_project_management);
        --auto_container_collaboration_management_color: var(--on_background_light_container_collaboration_management);

        --auto_label_color: var(--on_background_light_label);
        --auto_input_field_color: var(--on_background_light_input_field);
        --auto_placeholder_text_color: var(--on_background_light_placeholder_text);
        --auto_textarea_color: var(--on_background_light_textarea);
        --auto_selected_button_color: var(--on_background_light_selected_button);
        --auto_unselected_button_color: var(--on_background_light_unselected_button);
        --auto_selected_button_text_color: var(--on_background_light_selected_button_text);
        --auto_unselected_button_text_color: var(--on_background_light_unselected_button_text);
        --auto_button_hover_color: var(--on_background_light_button_hover);

        --auto_border_color: var(--border_color_light);
    }
}


body {
    background-color: var(--auto_background_color);
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    color: var(--auto_text_color);
    min-height: 100vh;
    padding-top: 60px;
    transition: 0.3s;
}

hr {
    color: var(--auto_text_color);
    opacity: .6;
}

.global_header {
    background-color: var(--auto_header_color);
}

.global_footer {
    background-color: var(--auto_footer_color);
}

.link {
    text-decoration: none;
    cursor: pointer;
    color: var(--auto_text_color);
}

.global_title {
    text-align: center;
    color: var(--auto_title_color);
}

.global_subtitle {
    color: var(--auto_subtitle_color);
    text-align: center;
    margin-left: 5px;
    opacity: .85;
}

.global_text_accent {
    color: var(--auto_accent_color_1);
}

.global_icon {
    color: var(--auto_icon_color);
}

.global_container_gradient {
    background-image: var(--auto_container_gradient_color);
    border-radius: 70px;
    padding: 20px;
    text-align: center;
    margin: auto;
}

.global_container_1 {
    background-color: var(--auto_container_color_1);
    border-radius: 20px;
    border: none;
    padding: 20px;
}

.global_container_2 {
    background-color: var(--auto_container_color_2);
    border-radius: 20px;
    border: none;
    padding: 20px;
}

.global_container_3 {
    background-color: var(--auto_container_color_3);
    border-radius: 20px;
    border: none;
    padding: 10px 20px;
}

.global_container_4 {
    background-color: var(--auto_container_color_4);
    border-radius: 20px;
    border: none;
    padding: 10px 20px;
}

.global_container_5 {
    background-color: var(--auto_container_color_5);
    border-radius: 20px;
    border: none;
    padding: 20px;
}


.global_label {
    margin-bottom: 3px;
    font-size: 17px;
    font-weight: bold;
    opacity: 70%;
    padding: 5px;
    color: var(--auto_label_color);
}

.global_input_field {
    background-color: var(--auto_input_field_color);
    color: var(--auto_text_color);
    box-sizing: border-box;
    border-radius: 12px;
    padding: 10px;
    border: 1.5px solid var(--auto_border_color);
    max-width: 100%;
    min-width: 100%;
}

::placeholder {
    color: var(--auto_placeholder_text_color);
}

.global_textarea {
    background-color: var(--auto_textarea_color);
    color: var(--auto_text_color);
    resize: none;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;

    border-radius: 10px 10px 16px 16px;
    padding: 15px;
    color: var(--auto_text_color);
    box-sizing: border-box;
    border-radius: 12px;
    padding: 10px;
    max-width: 100%;
    min-width: 100%;
    border: 1.5px solid var(--auto_border_color);
}

.global_selected_bouton {
    background-color: var(--auto_selected_button_color);
    color: var(--auto_selected_button_text_color);
    padding: 13px 30px 13px 30px;
    border: 0px;
    width: fit-content;
    border-radius: 50px;
    font-weight: bold;
}

.global_unselected_bouton {
    background-color: var(--auto_unselected_button_color);
    color: var(--auto_unselected_button_text_color);
    padding: 13px 30px 13px 30px;
    border: 0px;
    width: fit-content;
    border-radius: 50px;
    font-weight: bold;
}


/* Couleur boutons on hover */

.global_select_dropdown {
    backdrop-filter: blur(12px) saturate(150%);
    list-style-type: none;
    padding: 6px;
    margin: 0;
    width: max-content;
    border-radius: 12px;
    background-color: #ffffff18;
    box-shadow:
        0px 1.2px 2.2px rgba(0, 0, 0, 0.02),
        0px 2.9px 5.3px rgba(0, 0, 0, 0.028),
        0px 5.4px 10px rgba(0, 0, 0, 0.035),
        0px 9.6px 17.9px rgba(0, 0, 0, 0.042),
        0px 18px 33.4px rgba(0, 0, 0, 0.05),
        0px 43px 80px rgba(0, 0, 0, 0.07);
}