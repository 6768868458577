.quoted_post_container {
    /* margin-left: 84px; */
    background-color: var(--auto_container_color_1);
    border-radius: 16px;
    padding: 10px 20px;
    text-decoration: none;
    color: var(--auto_text_color);
    transition: 0.2s;
}

.quoted_post_container:hover {
    background-color: var(--auto_container_color_2);
    transition: 0.2s;
}

.header {
    display: flex;
    align-items: center;
    gap: 8px;
    height: fit-content;
    /* line-height: 10px; */
}

.image_profil {
    object-fit: cover;
    border-radius: 50%;
    height: 24px;
    width: 24px;
}

#conteneur {
    margin: 30px 0px 20px 0px;
}

.nom_affichage {
    margin-left: 3px;
}

#conteneur_post {
    margin: 30px 0px 30px 0px;
}