/* Footer */

#ConteneurFooter {
    margin: auto;
}

/*Footer Top Part */

#FooterHaut {
    margin: 10px 5% ;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 10px 10px 20px 10px;
}

/*Footer Haut: Section Gauche*/

#SectionGauche {
    max-width: 600px;
}

#SectionGauche h1 {
    color: var(--auto_title_color);
}

#SectionGauche h2 {
    opacity: 60%; 
}

#SectionGauche h3 {
    opacity: 40%;
}


/*Footer Haut: Section Droite*/
#SectionDroite {
    max-width: 600px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
}

.column {
    padding: 15px;
}

#SectionDroite h2 {
    opacity: 60%;
}

#SectionDroite h3 {
    opacity: 40%;
}



/*Footer Bas */

#FooterBas {
    background-color: var(--auto_footer_color);
    display: inline-grid;
    grid-template-columns: auto auto auto;
    width: 100%;
    font-size: 10px;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}


#TitreFooterBas {
    color: var(--auto_title_color);
    padding: 5px;
}

#FooterBasElements {
    opacity: .85;
}

/*Images Footer Bas */
#SectionIcones {
    display: inline-flex;
}

#SectionIcones img {
    padding: 5px;
}

@media only screen and (max-width: 850px) {
    #FooterBas {
        display: inline-grid;
        grid-template-columns: auto;
        text-align: center;
        align-items: center;
    }

    #FooterBas > * {
        width: 100vw;
    }
}