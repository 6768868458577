.conteneur {
    background-color: var(--auto_container_post_color);
}



.ligne_gestion_demande_collab {
    display: flex;
    /*align-items:center;*/
    border-radius: 20px;
    border: none;
    padding: 5px;
    background-color: var(--auto_container_color);
    margin: 5px 10px 20px 10px;

}

.titre_mes_demandes {
    text-align: center;
    background-color: var(--auto_container_post_color);
    margin: 25px;
    padding: 10px;
    border-radius: 20px 20px 5px 10px;


}

.conteneur_info_compte {
    margin: auto;
}

.icone {
    height: fit-content;
}

.titre_projet {
    text-align: center;
    font-weight: bold;
    color: var(--auto_text_color);


}

.nom_projet {
    font-weight: bold;
    color: var(--auto_text_color);
}

.nom_utilisateur {
    text-align: left;
    color: var(--auto_text_color);
    opacity: .85;
}

.conteneur_gestion {
    
    display: inline-flex;
    width: 100%;
    background-color: var(--auto_container_post_color);
    margin-bottom: 30px;
}

.conteneur_gestion div {
    margin-left: auto;
    margin-right: auto;
}

.conteneur_gestion_collab {
    margin: 60px 20px 80px 0px;
    padding: 0px 20px 10px 20px;
    border-radius: 20px 0px 00px 20px;
    background-color: var(--auto_container_gestion_collaboration);


}

.conteneur_gestion_projet {
    margin: 40px 0px;
    padding: 0px 20px 20px 20px;
    border-radius: 20px;
}


.conteneur_bouton {
    /*width: 30%;*/
    margin: 0 auto;
    display: flex;
}

.bouton {
    height: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    color: var(--auto_text_color);
}

#IconeX {
    margin-left: 10px;
}


.conteneur_gestion_projet {
    float: left;
    
}

.titre_mes_projets_rapide {
    text-align: center;
}

.ligne_gestion_projet {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 10%;
    padding: auto;
}


/*Popup Ajout Collaborateur*/

#coteneurAjoutCollaborateur {
    display: flex;
    flex-direction: column;
    margin: auto;
    padding: 25px;
    margin-bottom: 30px;
    max-width: 600px;
}

.conteneurBoutonX {
    align-self: end;
    border-radius: 50%;
    padding: 5px;
    margin-bottom: 10px;
}

.conteneurBoutonX:hover {
    background-color: var(--auto_container_post_color);
}

#titre {
    font-size: 20px;
    font-weight: bold;
    margin-top: 10px;
}

.icone {
    color: var(--auto_text_color);
    opacity: .6;
}

/*Select*/

.conteneurInputDropdown {
    display: inline-flex;
    width: 100%;
}

.conteneurDropdown {
    width: 240px;
}

#selectDropdown:invalid {
    color: green;
}


#selectDropdown {
    font: inherit;
    appearance: none;
    background-image:
        linear-gradient(45deg, transparent 50%, gray 50%),
        linear-gradient(135deg, gray 50%, transparent 50%),
        radial-gradient(#ddd 70%, transparent 72%);
    background-position:
        calc(100% - 20px) calc(1em + 2px),
        calc(100% - 15px) calc(1em + 2px),
        calc(100% - .5em) .5em;
    background-size:
        5px 5px,
        5px 5px,
        1.5em 1.5em;
    background-repeat: no-repeat;
}

#selectDropdown:focus {
    background-image:
        linear-gradient(45deg, white 50%, transparent 50%),
        linear-gradient(135deg, transparent 50%, white 50%),
        radial-gradient(gray 70%, transparent 72%);
    background-position:
        calc(100% - 15px) 1em,
        calc(100% - 20px) 1em,
        calc(100% - .5em) .5em;
    background-size:
        5px 5px,
        5px 5px,
        1.5em 1.5em;
    background-repeat: no-repeat;
    outline: 0;
}


#selectDropdown option {
    color: #000000;
}


/*Select Fin*/

.conteneurInput {
    margin-left: 10px;
    width: 100%;

}

#input {

    border-radius: 12px;
    margin-bottom: 20px;
    padding: 11px;

}

.conteneurBoutons {
    display: inline-block;
    width: 100%;
    text-align: center;
}


/*Création projet form*/

#conteneurCreerProjet {
    margin: auto;
    padding: 25px;
    border-radius: 20px;
    max-width: 500px;
}

.conteneurRadioBouton {
    display: inline-flex;
    background-color: var(--auto_container_color);
    padding: 20px;
    opacity: .7;
    margin-bottom: 20px;
    border-radius: 15px;
}

.labelRadioBouton {}

.radioBouton {
    margin-right: 15px;

}

#radioBouton {
    margin: auto;
    height: 100%;
    height: 25px;
    width: 25px;
    background-color: #eee;
    border-radius: 50%;
}


/*Filtre*/

.conteneurFiltreAjouterProjet {
    display: inline-flex;
    margin: auto;
}

.conteneurBoutonAjouter{
    margin: auto auto 10px auto;
    text-align: right;

}

.conteneurFiltreId {
    width: 100%;
    margin: 10px 10px;
    background-color: var(--auto_button2_color);
    min-width: 200px;
    width: fit-content;
    border-radius: 15px;
}

.filtre {

    padding: 10px;
    opacity: .9;
}


.conteneurBoutonAjouterProjet {
    display: inline-flex;


}

#boutonNouveauProjet {
    padding: 5px 8px;
    border: 0px;
    width: fit-content;
    border-radius: 13px;
    color: var(--auto_button_text_color);
    background-color: var(--auto_button_color);
    font-weight: bold;
    margin-right: 3px;
}

#iconeAjout {
    margin: auto;
    opacity: .9;
}



