*,
*::before,
*::after {
  box-sizing: border-box;
}

.body {
    max-width: 600px;
    margin: 0 auto;
}

.conteneurBoutons {
    text-align: center;
    margin-bottom: 3%;
}

#boutonAbonnement {
    margin-left: 20px;
}


.boutonSelectionne {
    text-align: center;
    border-radius: 18px;
    padding: 11px 30px;
    width: 150px;
    border: none;
    color: var(--auto_text_color);
    background-color: var(--auto_button_color);
    font-weight: bold;
}

.boutonNonSelectionne {

    text-align: center;
    border-radius: 18px;
    padding: 11px 30px;
    width: 150px;
    border: none;
    color: var(--auto_button_unselected_text_color);
    background-color: var(--auto_button_unselected_color);
    font-weight: bold;

}

.boutonNonSelectionne:hover {
background-color: var(--auto_button_unselected_hover_color);

}
.lien_vers_post {
    text-decoration: none;
    color: var(--auto_text_color);
}
    .lien_vers_post:hover {
        background-color: rgba(228, 228, 228, 0.279);
        transition: 0.2s;
    }

@media only screen and (max-width: 600px) {
    .body {
        width: 90vw;
    }
}

