.container {
    margin-left: 5%;
}

.container_parametres {
    border-radius: 0 20px 20px 0;
    border: none;
    padding: 10px 20px;
    background-color: var(--auto_container_color_2);
}

#containerDiv {
    width: 100%;
    text-align: right;
    padding-top: 10px;
}



.exemple_animation {
    padding-top: 10px;
    margin: auto;
}

#titleParametres {
    background-color: var(--auto_container_post_color);
    border-radius: 15px 15px 8px 8px;
    padding: 20px;
    margin-top: 10px;
}

#input {
    margin-bottom: 10px;
    margin-top: 10px;
}

.containerDiv {
    display: flex;
}

.hr {
    width: 85%;
}

.import_image {
    max-width: max-content;
    margin: 25px 0px;
}

.upload_input {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 70vw;
}

#conteneuurChoisirImg {
    display: flex;

}

#conteneurIcone {
    background-color: var(--auto_selected_button_color);
    border: 0px;
    width: fit-content;
    border-radius: 10px 0px 0px 10px;
    padding-left: 15px;
    padding-top: 3px;
    font-weight: bold;
}


input[type="file"] {
    display: none;
}

#custom-file-upload {
    background-color: var(--auto_selected_button_color);
    color: var(--auto_selected_button_text_color);
    padding: 13px 15px 13px 9px;
    border: 0px;
    width: fit-content;
    border-radius: 0px 10px 10px 0px;
    font-weight: bold;
    cursor: pointer;
}



#icone {
    color: var(--auto_text_color);
}

@media only screen and (max-width: 850px) {
    /* .container_parametres {
        width: 80%;
    } */
}

/* Dropdown Menu */

.selectInterface {
    padding-left: 15px;
    border: 0px;
    width: fit-content;
    border-radius: 15px;
    background-color: #aeaeae;
    font-weight: bold;
    min-width: 150px;
    max-height: min-content;
    font: inherit;
    appearance: none;
    background-image:
        linear-gradient(45deg, transparent 50%, rgb(79, 36, 122) 50%),
        linear-gradient(135deg, rgb(92, 40, 203) 50%, transparent 50%),
        radial-gradient(#7f45a6 70%, transparent 72%);
    background-position:
        calc(100% - 20px) calc(1em + 2px),
        calc(100% - 15px) calc(1em + 2px),
        calc(100% - .5em) .5em;
    background-size:
        5px 5px,
        5px 5px,
        1.5em 1.5em;
    background-repeat: no-repeat;
}

.selectInterface:focus {
    background-image:
        linear-gradient(45deg, rgb(92, 40, 203) 50%, transparent 50%),
        linear-gradient(135deg, transparent 50%, rgb(92, 40, 203) 50%),
        radial-gradient(#7f45a6 70%, transparent 72%);
    background-position:
        calc(100% - 15px) 1em,
        calc(100% - 20px) 1em,
        calc(100% - .5em) .5em;
    background-size:
        5px 5px,
        5px 5px,
        1.5em 1.5em;
    background-repeat: no-repeat;
    outline: 0;
}


.selectInterface option {
    color: #000000;
    background: rgb(255, 255, 255)
}




/*Select Fin*/



#descriptionsTheme {
    text-align: center;
}

.conteneurCouleur {
    margin: 20px auto;
    background-color: var(--auto_container_color_3);
    padding: 10px;
    width: 60%;
}

/* Slider */
.slider {
    -webkit-appearance: none;
    /* Override default CSS styles */
    appearance: none;
    width: 100%;
    /* Full-width */
    height: 30px;
    /* Specified height */
    background: #a4a4a4;
    /* Grey background */
    outline: none;
    /* Remove outline */
    opacity: 0.7;
    /* Set transparency (for mouse-over effects on hover) */
    -webkit-transition: .2s;
    /* 0.2 seconds transition on hover */
    transition: opacity .2s;
    border-radius: 20px;
    padding: 0 3px;
}

/* Mouse-over effects */
.slider:hover {
    opacity: 1;
    /* Fully shown on mouse-over */
}


.slider::-moz-range-thumb {
    width: 25px;
    /* Set a specific slider handle width */
    height: 25px;
    /* Slider handle height */
    background: #383838;
    /* Green background */
    border: none;
    border-radius: 20px;
    cursor: pointer;
    /* Cursor on hover */

}


.passkey_list_item {
    margin-top: 16px;
    background-color: var(--auto_container_color_3);
    padding: 2px 0px 2px 24px;
    border-radius: 12px;
    border: 0.5px solid var(--auto_border_color);
    box-shadow:
        0px 2px 2.2px rgba(0, 0, 0, 0.008),
        0px 4.9px 5.3px rgba(0, 0, 0, 0.012),
        0px 9.1px 10px rgba(0, 0, 0, 0.015),
        0px 16.3px 17.9px rgba(0, 0, 0, 0.018),
        0px 30.5px 33.4px rgba(0, 0, 0, 0.022),
        0px 73px 80px rgba(0, 0, 0, 0.03);
}

.titre_passkey_grid {
    display: grid;
    grid-template-columns: auto 60px;
}

.titre_passkey {
    padding-right: 12px;
    max-width: auto;
    text-overflow: ellipsis;
    overflow: hidden;
}

.bouton_supprimer {
    margin-top: -2px;
    width: 100%;
}

.icone_supprimer {
    color: rgb(192, 0, 0);
    font-size: 1.5rem;
}

.bouton_github {
    padding: 6px;
    margin: 0 auto;
    width: max-content;
    border-radius: 12px;
    background-color: #ffffff18;
}
.github_icon{
    font-size: 1.2rem;
    width: max-content;
    height: max-content;
    margin-left: -10px;
    margin-right: 10px;
}

.div_github {
    text-align: center;
}