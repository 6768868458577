.flexbox {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    padding-top: 5%;
    padding-bottom: 5%;
    margin-left: 5%;
    margin-right: 5%;
}


/* "Vous êtes déjà inscrit?..."*/
.conteneur_Texte {
    display: inline-flex;
    margin-bottom: 5%;
    margin-left: 8%;

}


#text_accent {
    margin-left: 7px;
    font-weight: 600;
}

.paragraphe {
    max-width: 500px;
    max-height: 500px;

}

