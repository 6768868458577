#conteneur_post {
margin: 30px 0px 30px 0px;


}

.conteneurDiv {
    width: 100%;

}

.header {
    width: 100%;
    /* display: flex;
    flex-grow: 1; */
}

.header_content {
    flex-direction: row;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    flex: 1;
}

#header_conteneur_droit {
    display: flex;
    flex: 8;
    background-color: var(--auto_container_color);
    border-radius: 15px 15px 9px 9px;
    padding-right: 20px;
}

/* HEADER - grid */
.grid_container {
    display: grid;
    grid-template-columns: auto min-content max-content 36px;
}

#infos_utilisateur {
    display: grid;
    grid-template-columns: min-content max-content;
    overflow: hidden;
    text-overflow: ellipsis;
}

.user_info {
    padding-left: 15px;
    line-height: 0.2;
    text-decoration: none;
    color: var(--auto_text_color);
    margin-bottom: auto;
    margin-top: auto;
}


.display_name {
    font-weight: 600;
    font-size: 1.2rem;
}


.username {
    opacity: 70%;
}


.date {
    align-items: end;
}

.image_profil {
    object-fit: cover;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin: 10px 5px 5px 0px;

}


.titre {
    text-decoration: none;
    color: var(--auto_text_color);
}

#inner_droit_nom_utilisateur {
    display: flex;
    flex: 8;
}



.contenu {
    word-wrap: break-word;
    white-space: pre-wrap;
    /* opacity: .8; */
    color: var(--auto_text_secondary_color);
    margin-bottom: 40px;
    padding-left: 2px;
}

.footer {
    margin-top: 15px;
    margin-bottom: 15px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    z-index: 0;
}

.bouton_interraction {
    padding: 8px 14px;
    border-radius: 100px;
    transition: 0.2s;
}

.bouton_interraction:hover {
    cursor: pointer;
    transition: 0.2s;
}

.icone {
    margin-right: 10px;
    margin-bottom: -2px;
    scale: 1.2;
    transition: 0.1s;
    z-index: 0;
}

.interraction_count {
    margin: auto;
}

/* === Widget like/dislike === */
.like_dislike_container {
    display: flex;
    flex-direction: row;
}

#bouton_interraction_like,
#bouton_interraction_dislike {
    padding: 8px 8px;
    margin: 0px 4px;
    border-radius: 100px;
    transition: 0.2s;
}

#bouton_interraction_options {
    padding: 8px 8px;
    height: fit-content;
    margin-right: 0;
    margin-top: 14px;
    margin-left: 8px;
    border-radius: 100px;
    transition: 0.2s;
}

.disliked_post {
    transition: 0.5s;
    color: rgb(170, 0, 0);
}

.liked_post {
    transition: 0.5s;
    color: rgb(0, 187, 62);
}

#icone_like,
#icone_dislike {
    margin: 0;
    margin-bottom: -2px;
    transition: 0.5s;
}

#icone_supprimer {
    scale: 1.2;
}

#icone_options {
    margin: 0;
}

@media (hover: hover) and (pointer: fine) {
    #icone_like:hover {
        color: rgb(0, 187, 62);
        transition: 0.1s;
    }

    #icone_dislike:hover,
    #icone_supprimer:hover{
        color: rgb(170, 0, 0);
        transition: 0.1s;
    }

    #bouton_interraction_like:hover {
        background-color: rgba(0, 255, 34, 0.192);
        transition: 0.1s;
    }

    #bouton_interraction_dislike:hover {
        background-color: rgba(255, 0, 0, 0.192);
        transition: 0.1s;
    }

    #bouton_interraction_reply:hover {
        background-color: rgba(255, 208, 0, 0.192);
        transition: 0.1s;
    }

    #icone_reply:hover {
        color: rgb(255, 123, 0);
        transition: 0.1s;
    }

    #bouton_interraction_partage:hover {
        background-color: rgba(153, 0, 255, 0.13);
        transition: 0.1s;
    }

    #icone_partage:hover {
        color: rgb(111, 0, 255);
        transition: 0.1s;
    }

    #bouton_interraction_options:hover{
        background-color: var(--auto_container_post_color);
        transition: 0.1s;
    }
}

/* === Widget like/dislike === */



/* .post_ferme {
    transition: 0.4s;
}

.post_ouvert {
    background-color: blue;
    transition: 0.4s;
} */

.share_menu,
.tooltip {
    z-index: 999;
    backdrop-filter: blur(12px) saturate(150%);
    list-style-type: none;
    padding: 6px;
    margin: 0;
    width: max-content;
    border-radius: 12px;
    background-color: #ffffff18;
    box-shadow:
        0px 1.2px 2.2px rgba(0, 0, 0, 0.02),
        0px 2.9px 5.3px rgba(0, 0, 0, 0.028),
        0px 5.4px 10px rgba(0, 0, 0, 0.035),
        0px 9.6px 17.9px rgba(0, 0, 0, 0.042),
        0px 18px 33.4px rgba(0, 0, 0, 0.05),
        0px 43px 80px rgba(0, 0, 0, 0.07);
}

.tooltip {
    padding: 12px 16px;
}

.share_menu_item {
    transition: 0.3s;
    padding: 8px 12px;
    outline: none;
    border-radius: 4px;
}

.share_menu_item:nth-child(2) {
    border-radius: 8px 8px 4px 4px;
}

.share_menu_item:not(:last-child) {
    margin-bottom: 4px;
}

.share_menu_item:last-child {
    border-radius: 4px 4px 8px 8px;
}

.share_menu_item:hover {
    transition: 0.3s;
    background-color: var(--auto_accent_color_1);
    color: var(--auto_selected_button_text_color);
    outline: none;
    cursor: pointer;
}

.share_menu_item>span {
    margin-left: 10px;
}

.share_menu_item>.share_menu_icon {
    margin-bottom: -2px;
}

.bouton_voir_plus {
    display: block;
    margin: auto;
    margin-top: 6px;
    background-color: transparent;
    border: 2px solid var(--auto_border_color);
    color: var(--auto_text_color);
    background-color: var(--auto_container_color_2);
    padding: 8px 16px 8px 28px
}

.edit_history_container {
    max-height: 50vh;
    overflow: scroll;
}

.edit_history_item {
    padding: 8px 12px;
    background-color: var(--auto_container_color_3);
    border-radius: 8px;
    margin-bottom: 4px;
}