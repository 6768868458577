.badge {
    margin-inline: 6px;
    font-size: 1.2rem;
}

.tooltip {
    backdrop-filter: blur(12px) brightness(40%);
    list-style-type: none;
    margin: 0;
    width: max-content;
    border-radius: 12px;
    background-color: #ffffff18;
    color: var(--on_background_dark_text);
    padding: 12px 16px;
    margin-bottom: 6px;
    z-index: 20;

    box-shadow:
        0px 1.2px 2.2px rgba(0, 0, 0, 0.02),
        0px 2.9px 5.3px rgba(0, 0, 0, 0.028),
        0px 5.4px 10px rgba(0, 0, 0, 0.035),
        0px 9.6px 17.9px rgba(0, 0, 0, 0.042),
        0px 18px 33.4px rgba(0, 0, 0, 0.05),
        0px 43px 80px rgba(0, 0, 0, 0.07);
}