#Conteneur_Header::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    backdrop-filter: blur(32px) saturate(200%);
    z-index: -1;
}

#Conteneur_Header {
    backdrop-filter: blur(32px) saturate(200%);
    z-index: 100;
    position: fixed;
    top: 0;
    width: 100vw;
    height: 84px;
    background-color: var(--auto_header_color);
}

#Conteneur_Interieur_Header {
    display: flex;
    justify-content: space-between;
    height: 100%;
    padding-inline: 16px;
    margin-inline: auto;
}

#LogoSite {
    margin-left: 15px;
}

#Conteneur_Interieur_Header>div {
    margin-block: auto;
}

.logo {
    height: 56px;
    fill: var(--auto_accent_color_1);
    stroke: var(--auto_accent_color_1);
    stroke-linejoin: bevel;
    stroke-width: 2px;
}

/* Menu dropdown */
#link {
    margin: auto auto auto 10px;
    font-size: 1.3rem;
}

.dropdown_menu_icon {
    font-size: 1.2rem;
    margin-bottom: -4px;
    margin-right: 12px;
}

.dropdown {
    margin-right: 16px;
}

.dropdown_menu::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    backdrop-filter: blur(12px) saturate(150%);
    z-index: -1;
}

.dropdown_menu {
    list-style-type: none;
    padding: 6px;
    margin: 0;
    width: max-content;
    border-radius: 12px;
    background-color: #ffffff18;
    box-shadow:
        0px 1.2px 2.2px rgba(0, 0, 0, 0.02),
        0px 2.9px 5.3px rgba(0, 0, 0, 0.028),
        0px 5.4px 10px rgba(0, 0, 0, 0.035),
        0px 9.6px 17.9px rgba(0, 0, 0, 0.042),
        0px 18px 33.4px rgba(0, 0, 0, 0.05),
        0px 43px 80px rgba(0, 0, 0, 0.07);
}

.dropdown:hover {
    cursor: pointer;
}

.dropdown_menu_item {
    transition: 0.3s;
    padding: 8px 12px;
    outline: none;
    border-radius: 4px;
}

.dropdown_menu_item {
    margin-bottom: 4px;
}

#premier_item_dropdown {
    border-radius: 8px 8px 4px 4px;
}

#dernier_item_dropdown {
    border-radius: 4px 4px 8px 8px;
    margin-bottom: -1px;
}

#linkConteneur {
    text-decoration: none;
    color: var(--auto_text_color);
}

.dropdown_menu_item:hover {
    background-color: var(--auto_accent_color_1);
    color: var(--auto_selected_button_text_color);
    transition: 0.3s;
    outline: none;
    cursor: pointer;
}

/* Menu dropdown */