#conteneur {
    margin-bottom: 15%;
}


#conteneur_titre {
    text-align: center;
}

#conteneur_commentaire {
    margin: 30px 0px 30px 0px;
    margin: auto;
    width: 93%;
    height: 300px;
    
}

#conteneur_formCommentaire {
    width: 100%;
}

#textarea {
    height: 200px;
}

.conteneurDiv {
    width: 100%;
}

.form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.6rem;
    width: 100%;
}

#input {
    border-radius: 17px 17px 10px 10px;
    padding: 15px;
    font-size: 16px;
    font-weight: bold;
}


#divCollab {
    width: 100%;
}

#inputCollab {
    margin-top: 10px;
    border-radius: 12px;
    
}

#conteneurDivFooter {
    text-align: right;
    padding-top: 15px;
}

#span {
    margin-right: 15px;
}

.conteneurToggleModeMarkdown {
    display: flex
}

.titreToggleModeMarkdown {
    text-align: center;
}

/* The switch - the box around the slider */
.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}

/* https://www.w3schools.com/howto/howto_css_switch.asp */

/* SLIDER PRIT DU LIEN AU DESSUS*/

/* Hide default HTML checkbox */
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}
  
/* The slider */
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}
  
.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: #2196F3;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }

.slider {
    border-radius: 34px;
}
  
.slider:before {
    border-radius: 50%;
}

/*À ajuster ou effacer ↓*/

.conteneur {
    display: flex;
    flex-direction: column;
    align-items: start;
    border-radius: 8px;
    background-color: rgba(255, 255, 255, 0.13);
    padding: 20px;
    margin-bottom: 30px;
}

.conteneurCommentaire {
    display: flex;
    flex-direction: column;
    align-items: start;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 30px;
}

/*À ajuster ou effacer ↑*/




/* Boutons */
.conteneurBoutons {
    text-align: center;
    margin: 3% auto;
    width: 100%;
}

#boutonQuestion, #boutonCollab {
    margin-left: 10px;
}



#selectURL {
    margin-top: 10px;
}

#ConteneurSelectURL {
    width: 100%;
}