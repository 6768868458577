
.form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.6rem;
    width: 450px;
    max-width: 550px;

}

.containerBouton {
    width: 100%;
    margin-top: 20px;
    text-align: center;
}

#passwordless_login_providers {
    width: fit-content;
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin: 0 auto;
    margin-top: 32px;
}

/* Ordinateur */
@media only screen and (min-width: 601px) {
    .global_grid {
        grid-template-columns: repeat(auto-fit, minmax(300px, 400px));
        width: 80vw;
    }
}

/* Mobile */
@media only screen and (max-width: 600px) {
    .form {
        width: 90vw;
        margin: auto;
    }
}
